/* src/styles/StarGlitter.css */

.star-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 9999;
}

.star, .dot {
  position: absolute;
  pointer-events: none;
  animation: twinkle 1s ease-out forwards;
}

.star {
  background: radial-gradient(circle at center, #ffdf00, rgba(255, 223, 0, 0.8));
  clip-path: polygon(
    50% 0%, 58% 38%, 100% 38%, 68% 59%, 
    79% 100%, 50% 75%, 21% 100%, 32% 59%, 
    0% 38%, 42% 38%
  );
  box-shadow: 0 0 10px rgba(255, 223, 0, 0.7), 0 0 20px rgba(255, 223, 0, 0.5), 0 0 30px rgba(255, 223, 0, 0.3);
}

.dot {
  background: rgba(255, 223, 0, 0.8);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(255, 223, 0, 0.5), 0 0 16px rgba(255, 223, 0, 0.3);
  width: 5px;  /* Reduced width */
  height: 5px; /* Reduced height */
}

@keyframes twinkle {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
