.tech-stack-container {
  text-align: center;
  background-color: #051139; /* Background color for the overall page */
  padding: 50px 20px;
}

/* Tabs for switching between different tech stack sections */
.tech-stack-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow tabs to wrap to a new line if necessary */
  gap: 10px;
  margin-bottom: 40px;
}

/* Individual tab styling */
.tech-tab {
  background: transparent;
  border: none;
  font-size: 16px; /* Adjusted font size to fit more text */
  color: #ffffff;
  cursor: pointer;
  padding: 8px 15px; /* Adjusted padding for better fit */
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease, color 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping within a tab */
}

/* Active tab styling */
.tech-tab.active {
  color: #5551ff;
  border-bottom: 2px solid #5551ff;
}

/* Tab hover effect */
.tech-tab:hover {
  color: #5551ff;
}

/* Content area for displaying the logos */
.tech-stack-content {
  background-color: white; /* White background for the content area */
  padding: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 65%; /* Ensure content doesn't overflow */
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
}

/* Wrapper for all logos */
.tech-logos-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* Row of logos within the wrapper */
.tech-logos-row {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the spacing between logos */
  flex-wrap: wrap; /* Allow logos to wrap if they don't fit in one row */
}

/* Logo item container to ensure consistent size */
.tech-logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px; /* Adjust the width for consistent spacing */
  height: 90px; /* Adjust the height for consistent spacing */
}

/* Styling for individual logos */
.tech-logo {
  max-width: 70px; /* Adjust the size of the logos */
  max-height: 70px;
  width: 100%; /* Make sure logos take full width of container */
  height: 100%; /* Make sure logos take full height of container */
  object-fit: contain; /* Ensure logos fit within their container */
  transition: transform 0.3s ease;
}

/* Logo hover effect */
.tech-logo:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.bounceInRight {
  visibility: visible;
  animation-name: bounceInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.bounceInLeft {
  visibility: visible;
  animation-name: bounceInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.tech-logos-row.bounceInRight:hover .tech-logo-item {
  animation-name: bounceInLeft;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .tech-stack-container {
    padding: 30px 15px;
  }

  .tech-stack-tabs {
    flex-direction: row; /* Keep tabs in a row */
    gap: 10px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  }

  .tech-tab {
    font-size: 16px;
    padding: 8px 15px;
    width: auto; /* Adjust width to auto to fit content */
    flex-shrink: 0; /* Prevent tabs from shrinking */
  }

  .tech-stack-content {
    padding: 30px;
  }

  .tech-logos-wrapper {
    gap: 15px;
  }

  .tech-logo-item {
    width: 70px; /* Decrease logo container size */
    height: 70px; /* Decrease logo container size */
  }

  .tech-logo {
    max-width: 60px; /* Decrease logo size */
    max-height: 60px;
  }
}

/* Extra Small Devices (up to 576px) */
@media (max-width: 576px) {
  .tech-stack-container {
    padding: 20px 10px;
  }

  .tech-stack-tabs {
    flex-direction: row; /* Keep tabs in a row */
    gap: 10px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  }

  .tech-tab {
    font-size: 14px;
    padding: 6px 10px;
    width: auto; /* Adjust width to auto to fit content */
    flex-shrink: 0; /* Prevent tabs from shrinking */
  }

  .tech-stack-content {
    padding: 20px;
    margin: unset;
    max-width: 100%;
  }

  .tech-logos-wrapper {
    gap: 10px;
  }

  .tech-logo-item {
    width: 60px; /* Further decrease logo container size */
    height: 60px; /* Further decrease logo container size */
  }

  .tech-logo {
    max-width: 50px; /* Further decrease logo size */
    max-height: 50px;
  }
}
