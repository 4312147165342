.footer-container {
  background-color: #051139; /* Updated background color */
  color: white;
  padding: 40px 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}

.footer-container.aligned {
  opacity: 1;
  transform: translateY(0);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 20px; /* Added gap for better spacing between sections */
}

.footer-section {
  flex: 1;
  margin: 0 20px;
  min-width: 250px; /* Prevent sections from becoming too narrow */
}

.about-section p, .contact-section p {
  margin: 0;
  line-height: 1.6;
}

.links-section {
  text-align: left;
}

.links-section h3 {
  margin-bottom: 20px;
}

.links-section nav {
  display: flex;
  flex-direction: column;
}

.links-section nav a {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.links-section nav a:hover {
  text-decoration: underline;
}

.contact-section h3 {
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: flex-start; /* Align icons to the left */
}

.social-icons a {
  color: white;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: white; /* Default hover color */
}

/* Instagram gradient hover */
.social-icons a[href*="instagram"]:hover {
  background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
  -webkit-text-fill-color: transparent;
  background-size: 100%;
}

/* WhatsApp hover */
.social-icons a[href*="wa.me"]:hover {
  color: #25D366;
}

/* Facebook hover */
.social-icons a[href*="facebook"]:hover {
  color: #1877F2;
}

/* LinkedIn hover */
.social-icons a[href*="linkedin"]:hover {
  color: #0077B5;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  margin: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center; /* Center text for smaller screens */
  }

  .footer-section {
      margin: 10px 0;
  }

  .links-section nav {
      align-items: center; /* Center links on smaller screens */
  }

  .social-icons {
      justify-content: center; /* Center social icons on smaller screens */
  }
}

/* Mobile Styles for Smaller Devices */
@media (max-width: 480px) {
  .footer-container {
      padding: 30px 15px;
  }

  .footer-section {
      margin: 0 0 20px 0;
      min-width: 100%; /* Ensure sections span the full width */
  }

  .social-icons a {
      font-size: 20px; /* Slightly smaller icons on very small screens */
  }

  .footer-bottom {
      margin-top: 30px;
      padding-top: 15px;
  }
}
