@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.main-container {
  background-color: #0f123d;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 20% 20%, #1b00ff, transparent 25%), 
                    radial-gradient(circle at 80% 30%, #ff00ff, transparent 20%),
                    radial-gradient(circle at 20% 80%, #ff00ff, transparent 20%);
  animation: pulse 5s infinite;
  z-index: 1;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  z-index: 1;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 20px;
  max-width: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-title {
  font-size: clamp(1.5rem, 4vw, 2.5rem); /* Reduced font size */
  margin-bottom: 15px;
}

.main-subtitle {
  font-size: clamp(0.9rem, 2.5vw, 1.2rem); /* Reduced font size */
  margin-bottom: 25px;
}

.contact-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: clamp(0.8rem, 2vw, 1rem); /* Reduced font size for button */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  align-self: center;
}

.contact-button:hover {
  background-color: #0056b3;
}

/* Hide subtitle and adjust button for smaller screens */
@media (max-width: 768px) {
  .main-container {
    padding: 0 20px;
    height: auto;
  }

  .content-container {
    padding: 15px;
    max-width: 90%;
    top: 40%;
    transform: translate(-50%, -40%);
  }

  .main-subtitle {
    display: none; /* Hide subtitle text */
  }

  .contact-button {
    font-size: clamp(0.7rem, 1.8vw, 0.9rem);
    padding: 8px 16px;
    margin-top: 15px; /* Adjust button position */
  }
}

/* Extra Small Devices (up to 480px) */
@media (max-width: 480px) {
  .main-container {
    padding: 0 15px;
    height: auto;
  }

  .content-container {
    padding: 10px;
    max-width: 95%;
    top: 40%;
    transform: translate(-50%, -40%);
  }

  .main-title {
    font-size: clamp(1rem, 3vw, 1.5rem); /* Smaller font size for small mobile devices */
  }

  .contact-button {
    font-size: clamp(0.6rem, 1.5vw, 0.8rem);
    padding: 6px 12px;
    margin-top: 15px; /* Adjust button position */
  }
  .hero-image{
    height: 230px;
  }
}