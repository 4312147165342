.header-container {
  background-color: #0f123d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  position: relative;
}

.logo {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
}

nav.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

nav.nav-links a, nav.nav-links .contact-button {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s;
}

nav.nav-links a:hover {
  color: #ffc107;
}

.contact-button {
  background-color: #ffc107;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #e6a807;
}

/* Hamburger icon */
.hamburger-icon {
  display: none; /* Hidden by default for desktop view */
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.hamburger-icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.hamburger-icon path {
  stroke: #fff;
  stroke-width: 4;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Sidebar styles */
.nav-links.open {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #0f123d;
  padding-top: 60px;
  z-index: 1000;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
  display: none;
}

.sidebar-overlay.visible {
  display: block;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header-container {
    padding: 20px; /* Adjust padding for mobile view */
    justify-content: space-between;
  }

  .logo {
    margin-right: auto; /* Push the logo to the left */
  }

  nav.nav-links {
    display: none;
  }

  .hamburger-icon {
    display: block; /* Display the hamburger icon on mobile view */
    margin-left: 20px; /* Add space between the logo and hamburger icon */
    position: absolute; /* Keep the icon at the top-right, even when sidebar is open */
    top: 20px;
    right: 20px;
    z-index: 1001;
  }

  .nav-links.open {
    display: flex;
    transform: translateX(0);
  }

  .hamburger-icon svg {
    width: 30px;
    height: 30px;
    fill: none;
  }

  .hamburger-icon path {
    stroke: #fff;
    stroke-width: 3;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}

@media (max-width: 768px) {
  /* Adjustments for general elements */
  body {
    padding: 10px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.5rem;
    text-align: center;
  }

  p {
    font-size: 1rem;
    text-align: center;
  }

  .container, .content, .section, .wrapper {
    padding: 10px;
    margin: 0 auto;
    max-width: 100%;
  }

  /* Flex and Grid adjustments */
  .flex-container, .grid-container {
    flex-direction: column;
    gap: 15px;
  }

  .grid-item {
    width: 100%;
  }

  /* Button adjustments */
  .button, .btn, .action-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
  }

  /* Form adjustments */
  .form-group, .form-container {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .form-input, .form-field, input[type="text"], input[type="email"], input[type="password"], textarea {
    width: 100%;
    font-size: 1rem;
  }

  /* Specific classes for individual files */
  .service-options {
    flex-direction: column;
    gap: 10px;
  }

  .service-button {
    width: 100%;
    padding: 10px;
  }

  .contact-content {
    flex-direction: column;
    gap: 15px;
  }

  .submit-button {
    width: 100%;
    font-size: 1.2rem;
  }

  .contact-info, .footer-info {
    text-align: center;
  }

  .contact-info p, .footer-info p {
    font-size: 1rem;
  }
}
