.services-container {
  background-color: #051139; /* Page background color */
  padding: 50px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.services-title {
  font-size: 2em;
  color: #fff; /* Title color */
  margin-bottom: 30px;
}

.service-slider {
  display: flex;
  gap: 20px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  position: relative;
}

.service-card {
  width: 28%; /* Slightly reduced card width */
  height: 320px; /* Slightly reduced card height */
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  transition: transform 1s ease, height 1s ease, box-shadow 1s ease;
}

.service-card:not(.zoom) {
  transform: scale(0.8);
}

.service-card.zoom {
  transform: scale(1.0); /* Increased zoom effect */
  z-index: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-img {
  width: 15%; /* Reduced logo size */
  height: auto;
  object-fit: contain;
  margin: 0 auto 10px auto;
}

.service-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #051139; /* Optional: Title color to contrast with card */
}

.service-description {
  font-size: 1em;
  line-height: 1.5;
  color: #333; /* Description text color */
}

/* For devices with width less than or equal to 768px */
@media (max-width: 768px) {
  .service-card {
    width: 90vw; /* Slightly reduced width for mobile */
    height: auto; /* Adjust height to auto for flexibility */
    opacity: 0; /* Hide non-zoomed cards */
    transition: opacity 0.5s ease, transform 0.5s ease, height 0.5s ease; /* Added opacity transition */
    margin: 0; /* Remove any margins */
    padding: 20px; /* Add padding inside the card */
  }

  .service-card.zoom {
    opacity: 1; /* Show the zoomed card */
    margin: 0; /* Remove margins to ensure full width */
  }

  .service-slider {
    flex-direction: row; /* Keep horizontal alignment */
    gap: 0; /* No gap between cards */
    overflow-x: scroll; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Snap to cards */
    scroll-behavior: smooth; /* Smooth scrolling */
  }

  .service-card {
    scroll-snap-align: center; /* Snap cards to center */
  }

  .services-container {
    overflow: hidden;
    padding: 0; /* Remove padding to avoid extra space */
  }

  .service-img {
    width: 15%; /* Further reduced the width of the logo for mobile */
    height: 50px; /* Adjust the height of the logo */
    margin-bottom: 5px; /* Reduce the gap between the image and the title */
  }

  .service-title {
    font-size: 1.2em; /* Slightly reduce font size */
    margin-bottom: 5px; /* Reduce the margin below the title */
  }

  .service-description {
    font-size: 0.9em; /* Slightly reduce font size */
  }
}

/* For devices with width less than or equal to 480px */
@media (max-width: 480px) {
  .service-card {
    width: 85vw; /* Reduced width for smaller devices */
    height: auto; /* Ensure flexibility in height */
    opacity: 1; /* Hide non-zoomed cards */
    margin: 0; /* Remove any margins */
    padding: 20px; /* Reduce padding inside the card */
    transform: scale(0.95); /* Ensure all non-zoomed cards are scaled down */
    transition: opacity 1s ease, transform 1s ease, height 1s ease; /* Smooth transition for opacity and transform */
  }

  .service-card.zoom {
    opacity: 1; /* Show the zoomed card */
    transform: scale(0.90); /* Ensure the zoomed card is scaled correctly */
    z-index: 1; /* Ensure the zoomed card is on top */
    height: auto; /* Adjust height for the zoomed card */
    margin: 0; /* Remove margins to ensure full width */
  }

  .service-slider {
    display: flex;
    gap: 0;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    scroll-snap-type: x mandatory; /* Snap to cards */
    scroll-behavior: smooth; /* Smooth scrolling */
  }

  .service-card {
    scroll-snap-align: center; /* Snap cards to center */
  }

  .services-container {
    overflow: hidden;
    padding: 0; /* Remove padding to avoid extra space */
  }

  .service-img {
    width: 20%; /* Further increase the width of the image */
    height: 50px; /* Further adjust the height of the image */
    margin-bottom: 5px; /* Reduce the gap between the image and the title */
  }

  .service-title {
    font-size: 1em; /* Further reduce font size */
    margin-bottom: 5px; /* Reduce the margin below the title */
  }

  .service-description {
    font-size: 0.8em; /* Further reduce font size */
  }
}
