body {
  font-family: 'Poppins', sans-serif;
  background-color: #0D1849;
  color: white;
  margin: 0;
  padding: 0;
}

.contact-container {
  background-color: #0D1849;
  color: white;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
}

.contact-heading {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-align: left;
}

.contact-subheading {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: left;
}

.contact-content {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.form-section {
  flex: 2;
  min-width: 300px;
  opacity: 0;
  transform: translateX(100%); /* Slide in from right */
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.contact-info {
  flex: 1;
  font-size: 1.2rem;
  text-align: left;
  padding-top: 200px;
  min-width: 250px;
  align-self: flex-start;
  margin-left: 100px;
  opacity: 0;
  transform: translateX(-100%); /* Slide in from left */
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

/* Animation state when in view */
.form-section.in-view, .contact-info.in-view {
  opacity: 1;
  transform: translateX(0); /* Move to the final position */
}

.service-options {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.service-button {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  will-change: transform;
}

.service-button.active,
.service-button:hover {
  background-color: #5551ff;
  color: white;
  transform: translateY(-5px);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form__group {
  position: relative;
  padding: 25px 0 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ffffff;
  outline: 0;
  font-size: 1.3rem;
  color: #ffffff;
  padding: 10px 0;
  background: transparent;
  transition: border-color 0.2s, transform 0.3s;
  will-change: transform;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 25px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus ~ .form__label,
.form__field:not(:placeholder-shown) ~ .form__label {
  top: -20px;
  font-size: 1rem;
  color: #5551ff;
}

.form__field:focus {
  border-color: #5551ff;
  transform: translateY(-3px);
}

textarea.form__field {
  height: 120px;
}

.submit-button {
  background-color: #5551ff;
  border: none;
  padding: 15px 20px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  margin-top: 20px;
  transition: transform 0.3s;
  will-change: transform;
}

.submit-button:hover {
  transform: translateY(-5px);
}

.contact-info p {
  margin: 50px 0;
}

.contact-info p a {
  color: white;
  text-decoration: none;
}

.contact-info p a:hover {
  text-decoration: underline;
}

/* Mobile Styles */
@media (max-width: 768px) {
  body {
    padding: 10px;
  }

  .contact-container {
    padding: 20px;
    max-width: 100%;
  }

  .contact-heading {
    font-size: 2rem;
  }

  .contact-subheading {
    font-size: 1rem;
  }

  .contact-content {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .service-options {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .service-button {
    width: 100%;
    padding: 10px;
  }

  .submit-button {
    width: 100%;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .contact-info {
    text-align: center;
    padding-top: 0;
    margin-left: 0;
    margin-bottom: 20px;
    min-width: unset;
  }

  .contact-info p {
    font-size: 1rem;
    margin: 20px 0;
  }

  .form-section, .contact-info {
    transform: translateX(0); /* Disable animation on mobile */
    opacity: 1; /* Ensure sections are visible */
  }
}

/* Mobile Styles for Smaller Devices */
@media (max-width: 480px) {
  .contact-heading {
    font-size: 1.8rem;
  }

  .contact-subheading {
    font-size: 0.9rem;
  }

  .submit-button {
    width: 100%;
    font-size: 1rem;
    padding: 12px;
    margin-top: 20px;
  }

  .service-button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .contact-info {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 15px;
    align-self: center;
  }

  .contact-container {
    padding: 15px;
  }
}
