@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes text-fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.wording-main-container {
  background-color: #0f123d;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 100% 70%, #1b00ff, transparent 20%),
    radial-gradient(circle at 0% 30%, #9C08FF, transparent 20%);
  z-index: 1;
}

.star-icon {
  position: absolute;
  font-size: 20px;
  color: #ffffff;
  z-index: 2;
  opacity: 0.9;
}

.star-icon.pulse {
  animation: pulse 2s infinite;
}

.small-star {
  font-size: 5px; /* Smaller size for stars */
}

.top-left {
  top: 15%;
  left: 25%;
}

.top-right {
  top: 15%;
  right: 25%;
}

.bottom-left {
  bottom: 15%;
  left: 25%;
}

.content-container {
  position: absolute;
  z-index: 2;
  padding: 20px;
  max-width: 80%;
  background-color: rgba(0, 0, 0, 0.072);
  border-radius: 10px;
}

h1 {
  font-size: 2.5em;
  margin: 0;
  color: white;
  z-index: 2;
  animation: text-fade-in-up 2s ease-out forwards; /* Apply the text animation */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .wording-main-container {
    height: 70vh; /* Reduce height for mobile screens up to 768px */
  }
  h1 {
    font-size: 2rem;
  }

  .star-icon {
    font-size: 10px; /* Smaller stars for mobile */
  }

  .small-star {
    font-size: 3px; /* Further reduce star size */
  }

  .top-left {
    top: 10%;
    left: 20%;
  }

  .top-right {
    top: 10%;
    right: 20%;
  }

  .bottom-left {
    bottom: 10%;
    left: 20%;
  }

  .content-container {
    padding: 15px;
    max-width: 90%; /* Increase max-width for mobile */
    background-color: rgba(0, 0, 0, 0); /* Increase background opacity for better text visibility */
  }
}

/* Extra Small Devices (up to 576px) */
@media (max-width: 576px) {
  .wording-main-container {
    height: 60vh; /* Further reduce height for very small screens up to 576px */
  }
  h1 {
    font-size: 1.8rem;
  }

  .star-icon {
    font-size: 8px; /* Smaller stars for very small screens */
  }

  .small-star {
    font-size: 2px; /* Further reduce star size */
  }

  .top-left {
    top: 8%;
    left: 15%;
  }

  .top-right {
    top: 8%;
    right: 15%;
  }

  .bottom-left {
    bottom: 8%;
    left: 15%;
  }

  .content-container {
    padding: 10px;
    max-width: 95%; /* Increase max-width for very small screens */
  }
}

/* Medium Devices (up to 992px) */
@media (max-width: 992px) {
  h1 {
    font-size: 2.2rem;
  }

  .star-icon {
    font-size: 15px; /* Adjust star size for medium screens */
  }

  .small-star {
    font-size: 4px; /* Adjust star size for medium screens */
  }

  .top-left {
    top: 12%;
    left: 22%;
  }

  .top-right {
    top: 12%;
    right: 22%;
  }

  .bottom-left {
    bottom: 12%;
    left: 22%;
  }

  .content-container {
    padding: 18px;
    max-width: 85%; /* Adjust max-width for medium screens */
  }
}

/* Large Devices (up to 1200px) */
@media (max-width: 1200px) {
  h1 {
    font-size: 2.4rem;
  }

  .star-icon {
    font-size: 18px; /* Adjust star size for larger screens */
  }

  .small-star {
    font-size: 5px; /* Adjust star size for larger screens */
  }

  .top-left {
    top: 14%;
    left: 24%;
  }

  .top-right {
    top: 14%;
    right: 24%;
  }

  .bottom-left {
    bottom: 14%;
    left: 24%;
  }

  .content-container {
    padding: 20px;
    max-width: 80%; /* Adjust max-width for larger screens */
  }
}
@media (max-width: 480px) {
  .wording-main-container {
    height: 55vh; /* Further reduce height for screens up to 480px */
  }
}