.easy-process-container {
  background-color: #0f123d;
  color: #fff;
  text-align: center;
  padding: 50px 20px;
  transition: transform 0.3s;
}

.easy-process-container h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.easy-process-container h3 {
  font-size: 2em;
  margin-bottom: 20px;
}

.easy-process-container p {
  font-size: 1em;
  margin-bottom: 40px;
}

.steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.step {
  width: 200px;
  margin: 20px;
  text-align: center;
}

.step img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.step h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.step p {
  font-size: 1em;
}

/* Mobile Styles */
@media (max-width: 768px) {
  body {
    padding: 10px;
  }

  .easy-process-container {
    padding: 30px 15px;
  }

  .easy-process-container h2 {
    font-size: 1.2em;
    margin-bottom: 8px;
  }

  .easy-process-container h3 {
    font-size: 1.6em;
    margin-bottom: 15px;
  }

  .easy-process-container p {
    font-size: 0.9em;
    margin-bottom: 30px;
  }

  .steps {
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 100%;
    margin: 10px 0;
  }

  .step img {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }

  .step h4 {
    font-size: 1em;
    margin-bottom: 8px;
  }

  .step p {
    font-size: 0.9em;
  }
}

/* Extra Small Devices (up to 576px) */
@media (max-width: 576px) {
  .easy-process-container {
    padding: 20px 10px;
  }

  .easy-process-container h2 {
    font-size: 1em;
    margin-bottom: 6px;
  }

  .easy-process-container h3 {
    font-size: 1.4em;
    margin-bottom: 12px;
  }

  .easy-process-container p {
    font-size: 0.8em;
    margin-bottom: 25px;
  }

  .steps {
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 100%;
    margin: 10px 0;
  }

  .step img {
    width: 45px;
    height: 45px;
    margin-bottom: 6px;
  }

  .step h4 {
    font-size: 0.9em;
    margin-bottom: 6px;
  }

  .step p {
    font-size: 0.8em;
  }
}

/* Medium Devices (up to 992px) */
@media (max-width: 992px) {
  .easy-process-container {
    padding: 40px 20px;
  }

  .easy-process-container h2 {
    font-size: 1.4em;
    margin-bottom: 9px;
  }

  .easy-process-container h3 {
    font-size: 1.8em;
    margin-bottom: 18px;
  }

  .easy-process-container p {
    font-size: 0.95em;
    margin-bottom: 35px;
  }

  .steps {
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .step {
    width: 180px;
    margin: 15px;
  }

  .step img {
    width: 55px;
    height: 55px;
    margin-bottom: 9px;
  }

  .step h4 {
    font-size: 1.1em;
    margin-bottom: 9px;
  }

  .step p {
    font-size: 0.95em;
  }
}

/* Large Devices (up to 1200px) */
@media (max-width: 1200px) {
  .easy-process-container {
    padding: 50px 25px;
  }

  .easy-process-container h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .easy-process-container h3 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .easy-process-container p {
    font-size: 1em;
    margin-bottom: 40px;
  }

  .steps {
    justify-content: space-around;
  }

  .step {
    width: 200px;
    margin: 20px;
  }

  .step img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  .step h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .step p {
    font-size: 1em;
  }
}
